import map from 'lodash/fp/map';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';

import {
    SCHEDULED, DOWNLOADING, DOWNLOADED, INSTALLING, FINISHED,
    rolloutStages,
} from '~/features/devices/constants/rolloutStages';
import {
    COMPLETED, FAILED, OPEN, ACTIVE,
    rolloutStates,
} from '~/features/devices/constants/rolloutStates';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { toShortVersion } from '~/features/base/utils/versionNumberConverter';

const whatLastRolloutStateCouldLookLile = [{
    deliverableId: 'distro',
    deliverableType: DeliverableType.DISTRO,
    deliverableVersion: {
        major: 2019,
        minor: 14,
        patch: 9,
    },
    stages: [{
        id: SCHEDULED,
        state: COMPLETED,
        timestamp: 1568953570,
    }, {
        id: DOWNLOADING,
        state: FAILED,
        timestamp: 1568954570,
    }, {
        id: DOWNLOADED,
        state: OPEN,
    }, {
        id: INSTALLING,
        state: OPEN,
    }, {
        id: FINISHED,
        state: OPEN,
    }],
}, {
    deliverableId: 'mdmc-app',
    deliverableType: DeliverableType.APP,
    deliverableVersion: {
        major: 4,
        minor: 6,
        patch: 1,
    },
    stages: [{
        id: SCHEDULED,
        state: COMPLETED,
        timestamp: 1568963570,
    }, {
        id: DOWNLOADING,
        state: COMPLETED,
        timestamp: 1568964570,
    }, {
        id: DOWNLOADED,
        state: COMPLETED,
        timestamp: 1568965570,
    }, {
        id: INSTALLING,
        state: ACTIVE,
        timestamp: 1568966570,
    }, {
        id: FINISHED,
        state: OPEN,
        timestamp: 1568967570,
    }],
}];

export const DeviceRolloutState = ({ rolloutState }) => {
    const content = map(rolloutStagesOfDeliverable => {
        const rolloutStageView = map(rolloutStageOfDeliverable => {
            const itemRolloutStage = rolloutStages[rolloutStageOfDeliverable.id];
            const itemRolloutState = rolloutStates[rolloutStageOfDeliverable.state];
            const classes = classNames('label label-condensed label-filled margin-right-5', itemRolloutState.className);
            return (
                <span className={classes}>
                    {itemRolloutStage.icon}
                    {itemRolloutState.label}
                </span>
            );
        }, rolloutStagesOfDeliverable.stages);
        return (
            <div>
                <span className='display-inline-block width-200'>
                    <DeliverableChip
                        deliverableId={rolloutStagesOfDeliverable?.deliverableId}
                        deliverableVersion={rolloutStagesOfDeliverable?.deliverableVersion}
                        deliverableType={rolloutStagesOfDeliverable?.deliverableType}
                    />
                </span>
                {rolloutStageView}
            </div>
        );
    }, whatLastRolloutStateCouldLookLile);
    return content;
};

export default DeviceRolloutState;

DeviceRolloutState.defaultProps = {};

DeviceRolloutState.propTypes = {
    // props
    rolloutState: PropTypes.object,
};
