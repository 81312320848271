import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import noop from 'lodash/fp/noop';
import find from 'lodash/fp/find';
import uid from '~/features/base/utils/uid';
import classNames from 'classnames';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import { parseQuery } from '~/features/base/utils/query';
import { filterSelector, pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { isShortVersion, toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { uploadDeliverableFile } from '~/features/deliverables/actions/deliverableFileActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { fetchFilteredArtifacts } from '~/features/deliverables/features/artifacts/actions/artifactActions';
import {
    showDeliverableDeletionDialog,
    showDeliverableEditorDialog,
    showDeliverableVersionDeletionDialog,
} from '~/features/deliverables/actions/deliverableActions';
import DeliverableVersionDetailsTable from '~/features/deliverables/components/DeliverableVersionDetailsTable';
import {
    deliverableIdsLoadingSelector,
    deliverableIdsPageItemsSelector,
    deliverablePageItemsSelector,
    deliverablesContextsSelector,
    deliverablesLoadingSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { DELIVERABLES_MANAGEMENT_PATH } from '~/features/base/constants/routes';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
    FETCH_DELIVERABLES_AMOUNT_INCREMENT,
} from '~/features/deliverables/constants/deliverablesParameters';
import { FormattedMessage } from 'react-intl';
import DeliverableIdList from '~/features/deliverables/components/DeliverableIdList';
import DeliverableVersionsList from '~/features/deliverables/components/DeliverableVersionsList';
import isEqual from 'lodash/fp/isEqual';
import DeliverableFiltersRowContainer from '~/features/deliverables/components/DeliverableFiltersRowContainer';
import { getDeliverableTypePath } from '~/features/deliverables/utils/utils';
import BundleTypeTabs from "~/features/deliverables/components/BundleTypeTabs";
import Cm4gTypeTabs from "~/features/deliverables/components/Cm4gTypeTabs";

export class DeliverableList extends Component {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    onEditDeliverableVersion = (event) => {
        const { deliverables } = this.props;
        const deliverableVersion = event.currentTarget.getAttribute('data-key-deliverable-version');
        const selectedDeliverableDetails = find(function(o) {
            return toShortSemanticVersion(o.deliverableVersion) === deliverableVersion;
        }, deliverables);
        const {
            deliverableId,
            description,
            downloadUrl,
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        } = selectedDeliverableDetails;
        this.props.onShowDeliverableEditorDialog({
            isNew: false,
            isDeliverableVersion: true,
            deliverableId,
            description,
            downloadUrl,
            deliverableVersion,
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        });
    };

    onDeleteDeliverableVersion = (event) => {
        const deliverableId = event.currentTarget.getAttribute('data-key-deliverable-id');
        const deliverableType = event.currentTarget.getAttribute('data-key-deliverable-type');
        const deliverableVersion = event.currentTarget.getAttribute('data-key-deliverable-version');
        this.props.onDeleteDeliverableVersion({
            deliverableId,
            deliverableType,
            deliverableVersion,
        });

    };

    onVersionSelect = (deliverableType, selectedDeliverableId, selectedDeliverableVersion, owningContext) => {
        const { search } = this.props;
        const query = parseQuery(search);
        const newPage = 0;
        const newSize = !query.size ? FETCH_DELIVERABLES_AMOUNT_INCREMENT : Number(query.size);
        const path = this.getPath(deliverableType, selectedDeliverableId, selectedDeliverableVersion);

        this.props.followRoute({
            route: path,
            query: {
                ...query,
                page: newPage,
                size: newSize,
            },
        });
    };

    onIdSelect = (deliverableType, selectedDeliverableId) => {
        const { search, match } = this.props;
        const query = parseQuery(search);
        const newPage = 0;
        const querySize = !query.size ? FETCH_DELIVERABLES_AMOUNT_INCREMENT : Number(query.size);
        const { deliverableId } = match.params;

        //update route only if the deliverableId changes
        if (deliverableId !== selectedDeliverableId) {
            const path = `/${DELIVERABLES_MANAGEMENT_PATH}/${getDeliverableTypePath(
                deliverableType)}/${selectedDeliverableId}`;

            this.props.followRoute({
                route: path,
                query: {
                    ...query,
                    page: newPage,
                    size: querySize,
                },
            });
        }
    };

    getPath = (deliverableType, selectedDeliverableId, selectedDeliverableVersion) => {
        return `/${DELIVERABLES_MANAGEMENT_PATH}/${getDeliverableTypePath(
            deliverableType)}/${selectedDeliverableId}/${selectedDeliverableVersion}`;
    };

    getDeliverableLabelName = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id="intl-msg:files"/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id="intl-msg:apps"/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id="intl-msg:distros"/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id="intl-msg:cm4g"/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id="intl-msg:bundle"/>;
            default:
                return <FormattedMessage id="intl-msg:files"/>;
        }
    };

    getDeliverableTooltipText = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id="intl-msg:createFile"/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id="intl-msg:createApp"/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id='intl-msg:createDistro'/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id='intl-msg:createCM4G'/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id='intl-msg:createBundle'/>;
            default:
                return <FormattedMessage id='intl-msg:createVersion'/>;
        }
    };

    updateRoute = (prevProps) => {
        const {
            search,
            deliverables,
            deliverableType,
            match,
            deliverableIds,
        } = this.props;

        const { deliverableVersion, deliverableId } = match.params;
        const query = parseQuery(search);
        const { deliverableIdPrefix, deliverableVersion: deliverableVersionPrefix } = query;

        //when deleting a deliverable
        if (prevProps.deliverableIds !== deliverableIds && !deliverableIds.includes(deliverableId)) {
            this.onIdSelect(deliverableType, deliverableIds[0]);
        }
        //routing from distro chip
        else if (prevProps.deliverables !== deliverables && deliverableId && deliverableVersionPrefix) {
            const firstMatchDeliverable = deliverables?.find((d) => toShortSemanticVersion(d.deliverableVersion) === deliverableVersionPrefix);
            firstMatchDeliverable && this.onVersionSelect(deliverableType, firstMatchDeliverable.deliverableId, toShortSemanticVersion(firstMatchDeliverable.deliverableVersion));

            //routing from  deliverable version chip
        } else if (prevProps.search !== search && deliverableIds?.includes(deliverableIdPrefix) && isShortVersion(deliverableVersionPrefix)) {
            this.onVersionSelect(deliverableType, deliverableIdPrefix, deliverableVersionPrefix);

            //when I have deliverable id selected but not a version
        } else if (deliverables && prevProps.deliverables !== deliverables && deliverableId && !deliverableVersion) {
            this.onVersionSelect(deliverableType, deliverableId, toShortSemanticVersion(deliverables[0].deliverableVersion));

            // when I don't have a deliverable id and version selected
        } else if (deliverables && prevProps.deliverables !== deliverables && !deliverableId && !deliverableVersion) {
            this.onVersionSelect(deliverableType, deliverables[0].deliverableId, toShortSemanticVersion(deliverables[0].deliverableVersion));
        }

    };

    render() {
        const {
            deliverableIds,
            deliverablesLoading,
            deliverableIdsLoading,
            deliverables,
            deliverableType,
            search,
            match,
        } = this.props;
        const { context, bundleType } = parseQuery(search);
        let { selectedDeliverableId, selectedDeliverableVersion } = this.props;
        const selectedDeliverableDetails = find(function(o) {
            return toShortSemanticVersion(o.deliverableVersion) === selectedDeliverableVersion;
        }, deliverables);
        const isDistroOrEssentialImage = isEqual(deliverableType, DELIVERABLE_TYPE_DISTRO) || (isEqual(deliverableType, DELIVERABLE_TYPE_BUNDLE) && bundleType === 'ESSENTIAL_IMAGE');

        const tableClassNames = classNames(
            'table',
            'table-hover',
            'table-layout-fixed',
            'table-bordered',
            'table-sticky',
        );
        return (
            <DefaultWhiteColumn className='padding-top-25 clearfix'>
                {deliverableType === DELIVERABLE_TYPE_BUNDLE && <BundleTypeTabs/>}
                {deliverableType === DELIVERABLE_TYPE_CM4G && <Cm4gTypeTabs/>}
                <DeliverableFiltersRowContainer deliverableType={deliverableType}>
                </DeliverableFiltersRowContainer>
                <div className='col-md-12 bg-white'>
                    {!isDistroOrEssentialImage &&
                        <DeliverableIdList deliverableIds={deliverableIds}
                                           deliverables={deliverables}
                                           deliverableIdsLoading={deliverableIdsLoading}
                                           deliverableType={deliverableType}
                                           selectedDeliverableId={selectedDeliverableId}
                                           tableClassNames={tableClassNames}
                                           selectedDeliverableIdChecked={selectedDeliverableId}
                                           search={search}
                                           match={match}
                                           triggerDataFetcher={this.props.triggerDataFetcher}
                                           registerDataInterest={this.props.registerDataInterest}
                                           onIdSelect={this.onIdSelect}
                                           onLoadMore={this.props.onLoadMore}
                                           deliverableIdsTotalCount={this.props.deliverableIdsTotalCount}
                                           showLoadMore={this.props.showLoadMore}
                                           getDeliverableTooltipText={this.getDeliverableTooltipText}
                                           getDeliverableLabelName={this.getDeliverableLabelName}
                                           renderDeliverableRows={this.renderDeliverableRows}>
                        </DeliverableIdList>
                    }
                    <DeliverableVersionsList deliverables={deliverables}
                                             deliverableIds={deliverableIds}
                                             deliverableType={deliverableType}
                                             selectedDeliverableId={selectedDeliverableId}
                                             selectedDeliverableVersion={selectedDeliverableVersion}
                                             selectedContext={context}
                                             tableClassNames={tableClassNames}
                                             search={search}
                                             match={match}
                                             onVersionSelect={this.onVersionSelect}
                                             getDeliverableTooltipText={this.getDeliverableTooltipText}>
                    </DeliverableVersionsList>
                    <DeliverableVersionDetailsTable
                        basePath={this.getPath(deliverableType, selectedDeliverableId, selectedDeliverableVersion)}
                        selectedDeliverableId={selectedDeliverableId}
                        selectedDeliverableVersion={selectedDeliverableVersion}
                        selectedDeliverableDetails={selectedDeliverableDetails}
                        deliverablesLoading={deliverablesLoading}>
                        isDistroOrEssentialImage={isDistroOrEssentialImage}
                    </DeliverableVersionDetailsTable>
                </div>
            </DefaultWhiteColumn>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            search,
        } = this.props;

        if (prevProps.search !== search) {
            const parsed = parseQuery(search);
            unregisterDataInterest(this.name);
            this.props.registerDataInterest(this.name, parsed);
            this.props.triggerDataFetcher();
        }
        this.updateRoute(prevProps);


    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (nextProps.deliverables !== this.props.deliverables) {
            return true;
        }
        return false;
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        contexts: deliverablesContextsSelector(state),
        filter: filterSelector(state),
        deliverables: deliverablePageItemsSelector(state),
        deliverableIdsLoading: deliverableIdsLoadingSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
        deliverableIds: deliverableIdsPageItemsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onShowDeliverableEditorDialog: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        uploadDeliverableFile: (owningContext, deliverableId, deliverableVersion, deliverableType, file) => {
            dispatch(uploadDeliverableFile(owningContext, deliverableId, deliverableVersion, deliverableType, file));
        },
        showDeliverableDeletionDialog: (options) => {
            dispatch(showDeliverableDeletionDialog(options));
        },
        onDeleteDeliverableVersion: (options) => {
            dispatch(showDeliverableVersionDeletionDialog(options));
        },
        fetchFilteredArtifacts: (options) => {
            dispatch(fetchFilteredArtifacts(options));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliverableList));

DeliverableList.defaultProps = {
    // props
    expanderOpen: false,
    deliverableType: '',
    selectedDeliverableId: '',
    selectedDeliverableVersion: '',
    showLoadMore: true,
    deliverables: [],
    deliverableIds: [],
    deliverableIdsLoading: false,
    canUpdateDeliverables: true,
    canDeleteDeliverables: true,
    canUpdateDeliverableVersions: true,
    canDeleteDeliverableVersions: true,
    deliverableIdsTotalCount: 0,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onShowPage: noop,
    onLoadMore: noop,
    onShowDeliverableEditorDialog: noop,
    onCreateDeliverableVersion: noop,
    onEditDeliverable: noop,
    showDeliverableDeletionDialog: noop,
    onDeleteDeliverableVersion: noop,
};

DeliverableList.propTypes = {
    // props
    expanderOpen: PropTypes.bool,
    deliverableType: PropTypes.string,
    selectedDeliverableId: PropTypes.string,
    selectedDeliverableVersion: PropTypes.string,
    showLoadMore: PropTypes.bool,
    deliverables: PropTypes.array,
    deliverableIds: PropTypes.array,
    deliverableIdsLoading: PropTypes.bool,
    canUpdateDeliverables: PropTypes.bool,
    canDeleteDeliverables: PropTypes.bool,
    canUpdateDeliverableVersions: PropTypes.bool,
    canDeleteDeliverableVersions: PropTypes.bool,
    deliverableIdsTotalCount: Number,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onShowPage: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCreateDeliverable: PropTypes.func,
    onShowDeliverableEditorDialog: PropTypes.func,
    onCreateDeliverableVersion: PropTypes.func,
    onEditDeliverable: PropTypes.func,
    showDeliverableDeletionDialog: PropTypes.func,
    onDeleteDeliverableVersion: PropTypes.func,
};
