import React, { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import GroupInput from '~/features/base/components/forms/GroupInput';
import SerialNumbersFormItem from '~/features/base/components/forms/SerialNumbersFormItem';
import { updateDeliverable } from '~/features/deliverables/actions/deliverableActions';
import { useDispatch } from 'react-redux';

interface WhitelistFormDialogProps {
    onHide: () => void;
    show: boolean;
    deliverable: any;
}

const WhitelistFormDialog: React.FC<WhitelistFormDialogProps> = ({ onHide, show, deliverable }) => {

    const dispatch = useDispatch();

    const [isValid, setIsValid] = useState(true);
    const whitelisting = { ...deliverable?.whitelisting };

    const {
        handleSubmit,
        control,
    } = useForm({
        defaultValues: {
            deviceBlacklist: whitelisting?.deviceBlacklist || [],
            deviceWhitelist: whitelisting?.deviceWhitelist || [],
            vehicleGroupBlacklist: whitelisting?.vehicleGroupBlacklist || [],
            vehicleGroupWhitelist: whitelisting?.vehicleGroupWhitelist || []
        }
    });

    const onSubmit = data => {
        if (!isValid) {
            return;
        }
        const requestParams = { ...deliverable, whitelisting: data };
        dispatch(updateDeliverable(requestParams));
        onHide();
    };

    const title = <FormattedMessage id={'intl-msg:whitelistAndBlacklist.title'}/>;

    const body = (<div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'padding-bottom-10'}>
                <div className={'h5 margin-bottom-10'}>
                    <span><FormattedMessage id='intl-msg:whitelist'/></span>
                </div>
                <Controller
                    control={control}
                    name='vehicleGroupWhitelist'
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <GroupInput groupNames={value} label={'intl-msg:groupWhitelist'} onChange={onChange}/>
                    }
                />
                <Controller
                    control={control}
                    name='deviceWhitelist'
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <SerialNumbersFormItem label={'intl-msg:deviceWhitelist'}
                                               serialNumbers={value}
                                               disabled={false}
                                               onChange={({ serialNumbers, isValid }) => {
                                                   onChange(serialNumbers);
                                                   setIsValid(isValid);
                                               }}/>}
                />
                <div className={'h5 margin-bottom-10 margin-top-20'}>
                    <span><FormattedMessage id='intl-msg:blacklisted'/></span>
                </div>
                <Controller
                    control={control}
                    name='vehicleGroupBlacklist'
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <GroupInput groupNames={value} label={'intl-msg:groupBlacklist'}
                                    onChange={onChange}/>
                    }
                />
                <Controller
                    control={control}
                    name='deviceBlacklist'
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <SerialNumbersFormItem label={'intl-msg:deviceBlacklist'}
                                               serialNumbers={value}
                                               disabled={false}
                                               onChange={({ serialNumbers, isValid }) => {
                                                   onChange(serialNumbers);
                                                   setIsValid(isValid);
                                               }}/>}
                />
            </div>

            <div className={'display-flex justify-content-end margin-top-20'}>
                <button type={'submit'} className={classNames('btn btn-primary')} onClick={() => {
                }}>
                    <FormattedMessage id='intl-msg:save'/>
                </button>
            </div>
        </form>
    </div>);

    return (<Dialog className={'whitelist-blacklist-dialog'}
                    show={true}
                    showCloseButton={true}
                    onHide={onHide}
                    title={title}
                    body={body}
                    footer={<></>}
                    useOverflow
    />);
};

export default WhitelistFormDialog;
