import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import DeliverableEditorForm from '~/features/deliverables/components/DeliverableEditorForm';
import {
    changeDeliverableEditor,
    createDeliverable,
    fetchAllDeliverableIds,
    updateDeliverable,
} from '~/features/deliverables/actions/deliverableActions';
import {
    appsDependenciesSelector,
    bundleDependenciesSelector,
    cm4gDependenciesSelector,
    deliverableEditorSelector,
    deliverablesContextsSelector,
    deliverableTypeSelector,
    bundleReleaseStateWarningSelector,
    bundleTypeSelector, cm4gTypeSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import {
    MONITORED_RELEASE_STATES,
    STATUS_CONFIRMED,
    STATUS_OPEN, STATUS_WARN_BUNDLE_RELEASE_STATE,
    STATUS_WARN_LEVEL_1,
    STATUS_WARN_LEVEL_2,
    WARN_LEVEL_1_VALUE,
    WARN_LEVEL_2_VALUE,
} from '~/features/base/constants/deliverablesWhiteListing';
import size from 'lodash/fp/size';
import DeliverableWarningsDialog from '~/features/base/components/dialogs/DeliverableWarningsDialog';

/**
 * Deliverable editor dialog
 */
export class DeliverableEditorDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            status: STATUS_OPEN,
            warningsList: [],
            showBundleReleaseStateDialog: false,
            isBundle: props.deliverableType === DELIVERABLE_TYPE_BUNDLE,
        };
    }

    onSaveDeliverable = () => {
        const { model, deliverableType } = this.props;
        if (!this.isValid(model)) {
            return;
        }
        if (model.isNew) {
            const newDeliverable = {
                ...model,
                deliverableType,
            };
            this.props.createDeliverable(newDeliverable);
        } else {
            this.props.updateDeliverable(model);
        }
    };

    onReview = (show, status) => {
        this.setState({
            showConfirm: show,
            status: status,
            whiteListedDevices: 0,
        });
    };

    whiteListingsCount() {
        const groupWhiteList = this.props.model.whitelisting?.vehicleGroupWhitelist;
        const groups = this.props.groups;
        const singleWhiteListedDevices = this.props.model.whitelisting?.deviceWhitelist?.length || 0;
        let whiteListedDevices = size(groupWhiteList)
            ? groupWhiteList.map(
                x => groups.find(
                    y => y.name === x),
            ).map(z => z.groupSize).reduce((a, b) => a + b)
            : 0;
        whiteListedDevices += singleWhiteListedDevices;
        return whiteListedDevices;
    }

    onCheck = () => {

        const { hasBundleReleaseStateWarning } = this.props;
        const { releaseState } = this.props.model;

        const warns = [];

        // Check bundle release state warning
        if (hasBundleReleaseStateWarning && hasBundleReleaseStateWarning.length) {
            warns.push(STATUS_WARN_BUNDLE_RELEASE_STATE);
        }

        // Check number of device whitelisted
        const whiteListedDevices = this.whiteListingsCount();
        if (MONITORED_RELEASE_STATES.includes(releaseState.toUpperCase())) {
            if (whiteListedDevices >= WARN_LEVEL_2_VALUE) {
                warns.push(STATUS_WARN_LEVEL_2);
            } else if (whiteListedDevices >= WARN_LEVEL_1_VALUE) {
                warns.push(STATUS_WARN_LEVEL_1);
            }
        }
        if (!warns.length || this.state.status === STATUS_CONFIRMED) {
            this.onSaveDeliverable();
        } else {
            this.setState({
                ...this.state,
                showConfirm: true,
                whiteListedDevices: whiteListedDevices,
                warningsList: warns,
            });
        }
    };

    isValid(model) {
        return !!model.deliverableId;
    }

    render() {
        const {
            showConfirm,
            whiteListedDevices,
            warningsList,
        } = this.state;
        const { groups, model, contexts, apps } = this.props;
        const title = this.renderTitle(model);
        const { releaseState } = model;
        const body = this.renderBody(groups, model, contexts);
        const footer = this.renderFooter(model);
        return (
            <>
                <Dialog className='file-deliverable-editor-dialog'
                        show={true}
                        showCloseButton={true}
                        onHide={this.props.hideModal}
                        title={title}
                        body={body}
                        footer={footer}
                        useOverflow
                />
                {warningsList.length &&
                    <DeliverableWarningsDialog
                        showConfirm={showConfirm}
                        warnings={warningsList}
                        whiteListedDevices={whiteListedDevices}
                        onReview={(sh, st) => this.onReview(sh, st)}
                        onSaveFileDeliverable={this.onSaveDeliverable}
                    />
                }
            </>
        );
    }

    getDeliverableTitle = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id="intl-msg:createFile"/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id="intl-msg:createApp"/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id="intl-msg:createDistro"/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id="intl-msg:createCM4G"/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id="intl-msg:createBundle"/>;
            default:
                return <FormattedMessage id="intl-msg:createFile"/>;
        }
    };

    renderTitle(model) {
        const isNew = getOr(true, 'isNew', model);
        if (isNew) {
            return this.getDeliverableTitle(this.props.deliverableType);
        }
        return <FormattedMessage id="intl-msg:editDeliverable.title"/>;
    }

    renderBody(groups, model, contexts) {
        const { deliverableType, hasBundleReleaseStateWarning, bundleType, cm4gType } = this.props;
        return (
            <DeliverableEditorForm model={model}
                                   bundleType={bundleType}
                                   cm4gType={cm4gType}
                                   groups={groups}
                                   deliverableType={deliverableType}
                                   contexts={contexts}
                                   hasWarning={hasBundleReleaseStateWarning}
                                   deliverablesDependencies={this.props[`${deliverableType.toLowerCase()}Dependencies`]}
                                   changeDeliverableEditor={this.props.changeDeliverableEditor}/>
        );
    }

    renderFooter(model) {
        const isValid = true;
        const isInProgress = getOr(false, 'isInProgress', model);

        const isReady = isValid && !isInProgress;
        return (
            <div className='btn-toolbar justify-content-end'>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                        onClick={this.onCheck}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id="intl-msg:save"/>}/> :
                            <FormattedMessage id="intl-msg:save"/>
                    }
                </button>
            </div>
        );
    }

    async componentWillMount() {
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_APP);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_CM4G);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_FILE);

    }
}

export const mapStateToProps = (state) => {
    return {
        deliverableType: deliverableTypeSelector(state),
        bundleType: bundleTypeSelector(state),
        cm4gType: cm4gTypeSelector(state),
        model: deliverableEditorSelector(state),
        groups: groupsSelector(state),
        contexts: deliverablesContextsSelector(state),
        distroDependencies: appsDependenciesSelector(state),
        cm4gDependencies: cm4gDependenciesSelector(state),
        appDependencies: appsDependenciesSelector(state),
        bundleDependencies: bundleDependenciesSelector(state),
        hasBundleReleaseStateWarning: bundleReleaseStateWarningSelector(state, deliverableEditorSelector(state), true),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createDeliverable: (newDeliverable) => {
            dispatch(createDeliverable(newDeliverable));
        },
        updateDeliverable: (model) => {
            dispatch(updateDeliverable(model));
        },
        changeDeliverableEditor: payload => {
            dispatch(changeDeliverableEditor(payload));
        },
        fetchFilteredDeliverables: (deliverableType) => {
            dispatch(fetchAllDeliverableIds(
                {
                    page: 0,
                    searchCriteria: {
                        deliverableType: deliverableType,
                    },
                },
            ));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableEditorDialog);

DeliverableEditorDialog.defaultProps = {
    // props
    model: {},
    contexts: [],
    apps: [],
    // functions
    createDeliverable: noop,
    updateDeliverable: noop,
    changeDeliverableEditor: noop,
    fetchFilteredDeliverables: noop,
};

DeliverableEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    contexts: PropTypes.array,
    apps: PropTypes.array,
    // functions
    createDeliverable: PropTypes.func,
    updateDeliverable: PropTypes.func,
    changeDeliverableEditor: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
};
