import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import CommaSeparatedList from '~/features/base/components/CommaSeparatedList';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import React from "react";

export const DeliverableChipList = ({ distroVersions, type, bsStyle }) => {
    if (distroVersions) {
        const versionsByType = distroVersions.filter(x => x.deliverableType === type);
        const distroChips = map(version =>
            <DeliverableChip
                deliverableId={version.deliverableId}
                deliverableVersion={version.deliverableVersion}
                deliverableType={version.deliverableType}
                deliverableSubtype={version.deliverableSubtype}
                bsStyle={bsStyle}
                displayValue={`${version.deliverableId}@${toShortSemanticVersion(version.deliverableVersion)}`}
            />, versionsByType);
        return (
            <CommaSeparatedList>{distroChips}</CommaSeparatedList>
        );
    } else {
        return null;
    }
};

export default DeliverableChipList;

DeliverableChipList.propTypes = {
    // props
    distroVersions: PropTypes.array,
    type: PropTypes.string,
    bsStyle: PropTypes.string,
};
