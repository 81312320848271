import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import AutoSuggestOTA from '~/features/base/components/forms/AutoSuggestOTA';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import { useFetchVehicleGroupsQuery } from '~/api/vehicleGroup/VehicleGroup.api';

interface GroupInputProps {
    label: string;
    groupNames: string[];
    disabled?: boolean;
    onChange: (newGroupNames: string[]) => void;
    hasError?: boolean;
}

const GroupInput: React.FC<GroupInputProps> = ({
    label,
    groupNames = [],
    disabled = false,
    onChange,
    hasError = false,
}) => {
    const [inputText, setInputText] = useState('');
    const optionsWithSelection: { label: string }[] = [];

    const { data: group } = useFetchVehicleGroupsQuery({});
    (group || []).forEach(group => {
        if (!groupNames.includes(group.name) && group.name.toUpperCase().startsWith(inputText.toUpperCase())) {
            optionsWithSelection.push({
                label: group.name,
            });
        }
    });

    const handleAddSelectedItem = (_: any, { suggestion }: { suggestion: { label: string } }) => {
        onChange([...groupNames, suggestion.label]);
        setInputText('');
    };

    const handleRemoveSelectedItem = (tagName: string) => {
        onChange(groupNames.filter(e => e !== tagName));
    };

    const handleSuggestionsChange = ({ value }: { value: string }) => {
        setInputText(value);
    };

    const inputProps = {
        placeholder: 'Start typing ...',
        value: inputText,
    };

    return (
        <div className='form-group'>
            <label className='control-label'>
                <FormattedMessage id={label}/>
            </label>
            <div className='form-group'>
                <AutoSuggestOTA
                    inputProps={inputProps}
                    disabled={disabled}
                    suggestions={optionsWithSelection}
                    noItemMessage={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                    onSuggestionSelected={handleAddSelectedItem}
                    onSuggestionsFetchRequested={handleSuggestionsChange}
                    openOnFocus
                    hasError={hasError}/>
                <TagList className='margin-top-15'>
                    {groupNames.map(item => (
                        <Tag key={item} deletable onClick={() => handleRemoveSelectedItem(item)}>
                            {item}
                        </Tag>
                    ))}
                </TagList>
            </div>
        </div>
    );
};

export default GroupInput;
