import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEVICES_PATH } from '~/features/base/constants/routes';
import { ENTITY_CONTROL_DEVICE } from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { showControlDeviceEditor } from '~/features/devices/actions/controlDeviceEditorActions';
import { exportDevices } from '~/features/devices/actions/exportActions';
import { fetchBaseSwVersions } from '~/features/baseSwVersions/actions/baseSwActions';
import { fetchFilteredControlDevices } from '~/features/devices/actions/controlDeviceActions';
import { mergeSelect, mergeSelectAll } from '~/features/higherorder/actions/selectionActions';

import {
    canCreateControlDevicesSelector,
    canReadControlDevicesSelector,
    canUpdateControlDevicesSelector,
    controlDevicePageCountSelector,
    controlDevicePageItemsSelector,
    controlDevicePageNumberSelector,
    controlDeviceTotalElementsSelector,
    devicesLoadingSelector,
    selectedAllControlDevicesSelector,
    selectedControlDeviceCountSelector,
    selectedControlDevicesSelector,
    showDeviceDetailsSidebarSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import { parseQuery } from '~/features/base/utils/query';
import { queryToSearchCriteria } from '~/features/devices/utils/deviceQuery';
import uid from '~/features/base/utils/uid';

import DeviceList from './DeviceList';
import { fetchHwVariants } from '~/features/hwVariants/actions/hwVariantsActions';
import {
    fetchVehicleSparePartNumbers,
} from '~/features/vehicleSparePartNumbers/actions/vehicleSparePartNumbersActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';
import {
    fetchDeviceStatusNames,
    fetchDeviceStatusTypes,
    setSelectedTabDeviceDetailsSidebar,
    setShowDeviceDetailsSidebar,
    toggleDeviceDetailsSidebar,
} from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { fetchVehicleVariants } from '~/features/vehicleVariants/actions/vehicleVariantsActions';
import { showControlDeviceQuickWhitelisting } from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import { REPORT } from '~/features/devices/constants/deviceDetailsTabs';

const FETCH_DEVICES_AMOUNT_INCREMENT = 50;

/**
 * Container for list of devices
 */
export class DevicesListContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onShowItem = ({ serialNumber }) => {
        const route = `/${DEVICES_PATH}/${serialNumber}`;
        this.props.followRoute({ route });
    };

    onSelectItem = (serialNumber, isSelected) => {
        if (isSelected) {
            this.props.unregisterDataInterest(this.name);
        } else {
            const { search } = this.props;
            const parsedQuery = parseQuery(search);
            this.registerDataInterest(parsedQuery);
        }
    };

    onSelectAll = () => {
        this.props.mergeSelectAll(ENTITY_CONTROL_DEVICE, !this.props.selectedAll);
    };

    onCreateDevice = () => {
        this.props.showControlDeviceEditor({
            isNew: true,
        });
    };

    onEditDevice = (options) => {
        this.props.showControlDeviceEditor({
            ...options,
        });
    };

    onExportDevices = (format = 'csv') => {
        // get criteria from props
        const { search, totalElements } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = queryToSearchCriteria(parsedQuery);
        const options = {
            format,
            totalElements,
            searchCriteria,
        };
        this.props.exportDevices(options);
    };

    onReload = () => {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        this.registerDataInterest(parsedQuery);
        this.props.triggerDataFetcher();
    };

    onLoadMore = () => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.limit ? query.limit : FETCH_DEVICES_AMOUNT_INCREMENT)
            + FETCH_DEVICES_AMOUNT_INCREMENT;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                limit: queryAmount,
            },
        });
    };

    render() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        return (
            <DeviceList {...this.props} {...parsedQuery}
                        fetchDevicesTotalAmountIncrement={FETCH_DEVICES_AMOUNT_INCREMENT}
                        onLoadMore={this.onLoadMore}
                        onReload={this.onReload}
                        onShowItem={this.onShowItem}
                        onSelectItem={this.onSelectItem}
                        onSelectAll={this.onSelectAll}
                        onCreateDevice={this.onCreateDevice}
                        onEditDevice={this.onEditDevice}
                        onExportDevices={this.onExportDevices}/>
        );
    }

    registerDataInterest(parsedQuery) {
        const { page, limit } = parsedQuery;
        const searchCriteria = queryToSearchCriteria(parsedQuery);

        const fetchFilteredControlDevicesOptions = {
            page: (page && page > 0) ? (page - 1) : 0,
            size: limit ? limit : FETCH_DEVICES_AMOUNT_INCREMENT,
            searchCriteria,
        };

        this.props.registerDataInterest(this.name, [
            fetchBaseSwVersions(),
            fetchFilteredGroups(),
            fetchHwVariants(),
            fetchDeviceStatusNames(),
            fetchDeviceStatusTypes(),
            fetchVehicleSparePartNumbers(),
            fetchVehicleVariants(),
            fetchFilteredControlDevices(fetchFilteredControlDevicesOptions),
        ]);
    }

    componentDidMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const { deviceSelected, serialNumberPrefix, showReport } = parsedQuery;
        if (deviceSelected) {
            this.props.setShowDeviceDetailsSidebar(serialNumberPrefix);
        }
        if (showReport) {
            this.props.setSelectedTabDeviceDetailsSidebar(REPORT);
        }
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        this.registerDataInterest(parsedQuery);

        this.props.triggerDataFetcher();
        // this.interval = setInterval(() => {
        //     this.props.triggerDataFetcher();
        // }, 20000);
    }

    componentWillUnmount() {
        const { showDeviceDetailsSidebar } = this.props;
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
        if (showDeviceDetailsSidebar) {
            this.props.toggleDeviceDetailsSidebar();
        }
    }

    // FIXME REACT16 Should probably be componentDidUpdate(prevProps, prevState, snapshot)
    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        const parsed = parseQuery(search);
        const nextSearchCriteria = queryToSearchCriteria(parsed);
        const currentSearchCriteria = queryToSearchCriteria(parseQuery(this.props.search));
        if (JSON.stringify(nextSearchCriteria) !== JSON.stringify(currentSearchCriteria)) {
            this.registerDataInterest(parsed);
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        canCreateControlDevices: canCreateControlDevicesSelector(state),
        canUpdateControlDevices: canUpdateControlDevicesSelector(state, ownProps),
        devicesLoading: devicesLoadingSelector(state),
        pageNumber: controlDevicePageNumberSelector(state),
        pageCount: controlDevicePageCountSelector(state),
        totalElements: controlDeviceTotalElementsSelector(state),
        controlDevices: controlDevicePageItemsSelector(state),
        selectedItems: selectedControlDevicesSelector(state),
        selectedAll: selectedAllControlDevicesSelector(state),
        selectedCount: selectedControlDeviceCountSelector(state),
        showDeviceDetailsSidebar: showDeviceDetailsSidebarSelector(state),
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        mergeSelect: (entity, select, id) => {
            dispatch(mergeSelect(entity, select, id));
        },
        mergeSelectAll: (entity, selectAll) => {
            dispatch(mergeSelectAll(entity, selectAll));
        },
        showControlDeviceEditor: (options) => {
            dispatch(showControlDeviceEditor(options));
        },
        exportDevices: (options) => {
            dispatch(exportDevices(options));
        },
        toggleDeviceDetailsSidebar: () => {
            dispatch(toggleDeviceDetailsSidebar());
        },
        setShowDeviceDetailsSidebar: (rowId) => {
            dispatch(setShowDeviceDetailsSidebar(rowId));
        },
        setSelectedTabDeviceDetailsSidebar: (tabId) => {
            dispatch(setSelectedTabDeviceDetailsSidebar(tabId));
        },
        showControlDeviceQuickWhitelisting: (serialNumber) => {
            dispatch(showControlDeviceQuickWhitelisting(serialNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesListContainer);

DevicesListContainer.defaultProps = {
    // props
    canReadControlDevices: false,
    canCreateControlDevices: false,
    canUpdateControlDevices: false,
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    controlDevices: [],
    pathname: '',
    search: '',
    selectedItems: [],
    selectedAll: false,
    selectedCount: 0,
    showDeviceDetailsSidebar: false,
    // functions
    followRoute: noop,
    triggerDataFetcher: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    mergeSelect: noop,
    mergeSelectAll: noop,
    showControlDeviceEditor: noop,
    exportDevices: noop,
    toggleDeviceDetailsSidebar: noop,
};

DevicesListContainer.propTypes = {
    // props
    canReadControlDevices: PropTypes.bool,
    canCreateControlDevices: PropTypes.bool,
    canUpdateControlDevices: PropTypes.bool,
    devicesLoading: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    controlDevices: PropTypes.array,
    pathname: PropTypes.string,
    search: PropTypes.string,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    selectedCount: PropTypes.number,
    showDeviceDetailsSidebar: PropTypes.bool,
    // functions
    followRoute: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    mergeSelect: PropTypes.func,
    mergeSelectAll: PropTypes.func,
    showControlDeviceEditor: PropTypes.func,
    exportDevices: PropTypes.func,
    toggleDeviceDetailsSidebar: PropTypes.func,
};
