import React from 'react';
import { FormattedMessage } from 'react-intl';
import {UdfDataParameterCodes} from "~/features/adaptOrders/constants/UdfDataParameterCodes";
import NoData from "~/features/base/components/NoData";

const UdfDataDetails = ({field}) => {
    if(!field) return <NoData/>;
    const ivd = field?.find(elem => elem.did === UdfDataParameterCodes.integrityValidationDataExpectedValueIVDMD)?.parameterLine;
    const eolCode = field?.find(elem => elem.did === UdfDataParameterCodes.vehicleManufacturerEOLProgInfoCode)?.parameterLine;

    if(!ivd && !eolCode) return <NoData/>;

    return (
        <>
            <div>
                <FormattedMessage id={'intl-msg:adaptOrder.udfDataMasterIVD'}/> : {ivd ? ivd : <NoData/>}
            </div>
            <div>
                <FormattedMessage id={'intl-msg:adaptOrder.udfDataEOLInfoCode'}/> : {eolCode ? eolCode : <NoData/>}
            </div>
        </>
    );
};

export default UdfDataDetails;
