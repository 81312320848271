import * as React from 'react';
import { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { useLazyFetchDeliverableVersionsQuery } from '~/api/deliverables/DeliverablesManagement.api';
import { DeliverableSearchCriteriaDeliverableTypeEnum } from '~/api/models/deliverable-search-criteria';
import { LastReportedEssentialImage } from '~/api/models/Bundle';
import { UNKNOWN } from '~/features/base/constants/filterOptions';

interface EssentialImageFormItemProps {
    value: LastReportedEssentialImage;
    onChange: (lastEssentialImageReported: LastReportedEssentialImage) => void;
}



const EssentialImageFormItem = ({ value, onChange }: EssentialImageFormItemProps) => {

    const [essentialImageVersions, setEssentialImageVersions] = useState<{
        label: string | React.JSX.Element,
        value?: string,
        id: string
    }[]>();


    const [getDeliverableVersions] = useLazyFetchDeliverableVersionsQuery();

    useEffect(() => {

        getVersions('essential-image', DeliverableSearchCriteriaDeliverableTypeEnum.BUNDLE);
    }, []);


    const getVersions = (deliverableId = '', deliverableType = '') => {
        getDeliverableVersions({ deliverableId, deliverableType }, true).then(result => {
            const versionsList = result?.data?.content.map(version => {
                return {
                    label: toShortSemanticVersion(version),
                    value: toShortSemanticVersion(version),
                    id: toShortSemanticVersion(version)
                };

            });
            versionsList && setEssentialImageVersions([{
                label: UNKNOWN,
                value: UNKNOWN,
                id: UNKNOWN
            }, ...versionsList]);

        });
    };

    const handleOnchange = (version) => {
        if (version.value === UNKNOWN) {
            onChange({ version: UNKNOWN });
        } else {
            onChange({ version: version.value });
        }

    }
    return (
        <div className='form-group form-group-semantic-version-range display-flex flex-row '>
            <div className='flex-basis-100pct'>
                <label className='control-label'><FormattedMessage
                    id='intl-msg:lastReportedEssentialImage.version'/></label>
                <Select
                    useFilter
                    placeholder={<FormattedMessage id='intl-msg:lastReportedEssentialImage.version'/>}
                    options={essentialImageVersions ? [...essentialImageVersions] : []}
                    value={value?.version ? [value.version] : undefined}
                    onChange={handleOnchange}/>
            </div>
        </div>
    );
};

export default EssentialImageFormItem;
