import compact from 'lodash/fp/compact';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import DeviceListTable from './DeviceListTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import {
    CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY,
    deviceColumnDescriptors,
} from '~/features/devices/constants/deviceColumnDescriptors';
import DeviceFilterDialog from '~/features/devices/components/dialogs/DeviceFilterDialog';
import { parseQuery } from '~/features/base/utils/query';
import { connect } from 'react-redux';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';
import split from 'lodash/fp/split';
import defaults from 'lodash/fp/defaults';
import { ALL } from '~/features/base/constants/filterOptions';
import DeviceListToolbar from '~/features/devices/components/DeviceListToolbar';
import DeviceFilterDisplay from '~/features/devices/components/DeviceFilterDisplay';
import { hotkeys } from 'react-keyboard-shortcuts';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import TableSettingsDialog from '@rio-cloud/rio-uikit/lib/es/TableSettingsDialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import DeviceTypeFormItem from '~/features/base/components/forms/DeviceTypeFormItem';
import { isSerialNumber } from '~/features/devices/utils/deviceQuery';

const defaultFilters = {
    hwVariant: ALL,
    hwVariantList: [],
    distroVersion: ALL,
    excludeDistroVersions: [],
    swVersion: ALL,
    vehicleGroupWhitelist: [],
    vehicleVariantsWhitelist: [],
    deviceWhitelist: [],
    vehicleGroupBlacklist: [],
    deviceBlacklist: [],
    inCustomerFleet: ALL,
    testReleasesActive: ALL,
    updatesActive: ALL,
    lastCheckForUpdateAfter: null,
    mileage: {},
};

export class DeviceList extends Component {
    constructor(props) {
        super(props);

        const defaultColumnLabels = {};
        let columnOrder = {};
        let hiddenColumns = [];
        const { intl } = this.props;
        try {
            const storedColumnSettings = JSON.parse(
                localStorage.getItem(CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY));
            if (storedColumnSettings) {
                // reset locally stored column settings if new columns settings were added
                if (storedColumnSettings && (size(storedColumnSettings.columnOrder) !== size(
                    deviceColumnDescriptors))) {
                    localStorage.removeItem(CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY);
                } else {
                    deviceColumnDescriptors.forEach(entry => {
                        defaultColumnLabels[entry.id] = intl.formatMessage({ id: entry.label });
                    });
                    columnOrder = Object.keys(defaultColumnLabels);
                }
                if (storedColumnSettings.columnOrder) {
                    columnOrder = storedColumnSettings.columnOrder;
                }
                if (storedColumnSettings.hiddenColumns) {
                    hiddenColumns = storedColumnSettings.hiddenColumns;
                }
            } else {
                deviceColumnDescriptors.forEach(entry => {
                    defaultColumnLabels[entry.id] = intl.formatMessage({ id: entry.label });
                });
                columnOrder = Object.keys(defaultColumnLabels);
            }
        } catch (e) {
            console.error(e);
        }

        this.state = {
            searchValue: '',
            sortBy: '',
            sortDir: SortDirection.ASCENDING,
            showTableSettingsDialog: false,
            showTableFilterDialog: false,
            defaultColumnLabels: defaultColumnLabels,
            columnOrder: columnOrder,
            hiddenColumns: [...hiddenColumns],
            columnsDetails: {},
        };
    }

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                if (this.state.showTableSettingsDialog) {
                    this.setState({ showTableSettingsDialog: false });
                }
            },
        },
    };

    toggleTableSettingsDialog = () => {
        this.setState({ showTableSettingsDialog: !this.state.showTableSettingsDialog });
    };

    toggleTableFilterDialog = () => {
        this.setState({ showTableFilterDialog: !this.state.showTableFilterDialog });
    };

    handleViewTypeChange = (viewType) => {
        this.setState({ viewType });
    };

    handleSearchValueChange = (prefixValue) => {
        let searchValue = prefixValue.trim()
        // check if prefix is serial or VIN
        if (searchValue.length === 0) {
            this.updateFilter({
                serialNumberPrefix: undefined,
                vinPrefix: undefined,
            });
        } else {
            // eslint-disable-next-line radix
            if (isSerialNumber(prefixValue)) {
                this.updateFilter({
                    serialNumberPrefix: searchValue.toLowerCase(),
                    vinPrefix: undefined,
                });
            } else {
                this.updateFilter({
                    vinPrefix: searchValue.toUpperCase(),
                    serialNumberPrefix: undefined,
                });
            }
        }
    };

    // For immediate effect
    handleColumnDetailsChange = (column, columnDetails) => {
        const updatedColumnsDetails = { ...this.state.columnsDetails };
        updatedColumnsDetails[column] = columnDetails;

        this.setState({
            columnsDetails: updatedColumnsDetails,
        });
    };

    handleColumnChange = (columnOrder, hiddenColumns, columnsDetails = this.state.columnsDetails) => {
        this.setState({
            columnOrder,
            hiddenColumns,
            columnsDetails,
        });
        localStorage.setItem(CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify({
            columnOrder,
            hiddenColumns,
            columnsDetails,
        }));
    };

    onLastCheckForUpdateChange = (value) => {
        this.updateFilter({
            lastCheckForUpdateAfter: value ? value.valueOf() : undefined,
        });
    };

    onClearFilters = () => {
        this.updateFilter({
            serialNumberPrefix: undefined,
            vinPrefix: undefined,
            hwVariant: undefined,
            deviceStatusNames: undefined,
            deviceStatusType: undefined,
            hwVariantList: undefined,
            deviceType: undefined,
            distroVersion: undefined,
            excludeDistroVersions: undefined,
            swVersion: undefined,
            vehicleManufacturerSparePartNumber: undefined,
            vehicleGroupWhitelist: undefined,
            deviceWhitelist: undefined,
            vinList: undefined,
            vehicleGroupBlacklist: undefined,
            deviceBlacklist: undefined,
            inCustomerFleet: undefined,
            updatesActive: undefined,
            vehicleVariantsWhitelist: undefined,
            testReleasesActive: undefined,
            lastCheckForUpdateAfter: undefined,
            installedDeliverables: undefined,
            lastEssentialImageReportedVersion: undefined,
        });
    };

    updateFilter = (filters) => {
        const skipSendRequest = filters.lastCheckForUpdateAfter !== undefined && isNaN(filters.lastCheckForUpdateAfter);
        if (skipSendRequest) {
            return;
        }
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 1,
            },
        });
    };

    render() {
        const content = this.renderContent();
        return (
            <div className='device-list row'>
                {content}
            </div>
        );
    }

    renderTableSettingsDialog() {
        // create function to recover from browser storage
        const defaultColumnOrder = Object.keys(this.state.defaultColumnLabels);

        // create function to recover from browser storage
        const columnLabels = this.state.defaultColumnLabels;

        const { showTableSettingsDialog, columnOrder, hiddenColumns, columnsDetails } = this.state;
        return (
            <TableSettingsDialog
                show={showTableSettingsDialog}
                title={'Table Settings'}
                onHide={this.toggleTableSettingsDialog}
                onColumnChange={this.handleColumnChange}
                defaultColumnOrder={defaultColumnOrder}
                defaultHiddenColumns={[]}
                columnOrder={columnOrder}
                hiddenColumns={hiddenColumns}
                columnLabels={columnLabels}
                // disabledColumns={disabledColumns}
                closeButtonText={'Close'}
                resetButtonText={'Reset to default'}
                searchPlaceholder={'Search by column name'}
                notFoundMessage={'No column found for this search value'}
                columnsDetails={columnsDetails}
                autoLabel={'Auto'}
                onColumnDetailsChange={this.handleColumnDetailsChange}
                immediateChange
            />
        );
    }

    renderTableFilterDialog() {
        return (
            <DeviceFilterDialog toggleTableFilterDialog={this.toggleTableFilterDialog} updateFilter={this.updateFilter}
                                clearFilter={this.onClearFilters}/>
        );
    }

    renderContent() {
        const {
            canReadControlDevices, canCreateControlDevices, canUpdateControlDevices, devicesLoading, controlDevices,
            fetchDevicesTotalAmountIncrement, totalElements, selectedItems, selectedAll, selectedCount, onSelectItem,
            onSelectAll, onLoadMore, onShowItem, onExportDevices, onCreateDevice, onEditDevice, search,
        } = this.props;
        const parsedQuery = parseQuery(search);
        const properQuery = {
            ...parsedQuery,
            excludeDistroVersions: compact(split(',', parsedQuery.excludeDistroVersions)),
            vehicleGroupWhitelist: compact(split(',', parsedQuery.vehicleGroupWhitelist)),
            deviceWhitelist: compact(split(',', parsedQuery.deviceWhitelist)),
            vehicleGroupBlacklist: compact(split(',', parsedQuery.vehicleGroupBlacklist)),
            vehicleVariantsWhitelist: compact(split(',', parsedQuery.vehicleVariantsWhitelist)),
            deviceBlacklist: compact(split(',', parsedQuery.deviceBlacklist)),
            isValidDeviceWhitelist: this.state.isValidDeviceWhitelist,
            isValidDeviceBlacklist: this.state.isValidDeviceBlacklist,
        };

        const { deviceType } = parsedQuery;

        const defaultedQuery = defaults(defaultFilters, properQuery);

        const {
            columnOrder,
            hiddenColumns,
            showTableSettingsDialog,
            showTableFilterDialog,
            columnsDetails,
        } = this.state;

        const showLoadMore = totalElements > controlDevices.length;

        return [
            <DefaultWhiteColumn key={'toolbar'} className='padding-top-20'>
                {showTableSettingsDialog && this.renderTableSettingsDialog()}
                {showTableFilterDialog && this.renderTableFilterDialog()}
                <DeviceListToolbar
                    canCreateControlDevices={canCreateControlDevices}
                    onShowControlDeviceEditor={onCreateDevice}
                    onExportDevices={onExportDevices}
                    onShowColumnSettings={this.toggleTableSettingsDialog}
                    onLastCheckForUpdateChange={this.onLastCheckForUpdateChange}
                    onSearchValueChange={this.handleSearchValueChange}
                    onShowFilterDialog={this.toggleTableFilterDialog}
                    defaultedQuery={defaultedQuery}
                    updateFilter={this.updateFilter}
                    clearFilter={this.onClearFilters}
                >
                    <div className='table-toolbar-group-left'>
                        <div className='display-inline-block padding-left-5 padding-top-10'>
                            {devicesLoading ? <Spinner/> : null}
                        </div>
                        <span className='padding-5'>
                                <FormattedMessage id='intl-msg:totalControlDevices.phrase'
                                                  values={{
                                                      totalElements: totalElements,
                                                      selectedCount: selectedCount,
                                                  }}/>
                        </span>
                        <a id='create-control-device-button'
                           className='btn btn-default margin-right-5'
                           onClick={this.props.onReload}>
                            <span className='rioglyph rioglyph-refresh' aria-hidden='true'></span>
                            <FormattedMessage id='intl-msg:reload'/>
                        </a>
                        <div className='display-inline-block padding-left-5 padding-top-10'>
                            <DeviceFilterDisplay defaultedQuery={defaultedQuery} updateFilter={this.updateFilter}/>
                        </div>
                    </div>
                </DeviceListToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn key={'quickFilters'} className=''>
                <DeviceTypeFormItem
                    onChange={this.updateFilter}
                    value={deviceType}
                    isRadioBtnGroup
                    showOptionAll
                />
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn key={'deviceList'} className='padding-top-20 padding-bottom-5 user-select-none'>
                <DeviceListTable
                    controlDevices={controlDevices}
                    hiddenColumns={hiddenColumns}
                    columnOrder={columnOrder}
                    canUpdateControlDevices={canUpdateControlDevices}
                    allowSelection={true}
                    selectedItems={selectedItems}
                    selectedAll={selectedAll}
                    onEditDevice={onEditDevice}
                    onSelectItem={onSelectItem}
                    onSelectAll={onSelectAll}
                    onShowItem={onShowItem}/>
            </DefaultWhiteColumn>,
            showLoadMore &&
            <DefaultWhiteColumn key={'loadMore'} className='text-center padding-bottom-20'>
                <a id='load-more-button'
                   className='btn btn-default'
                   onClick={onLoadMore}>
                    <FormattedMessage id='intl-msg:loadMore' values={{ num: fetchDevicesTotalAmountIncrement }}/>
                </a>
            </DefaultWhiteColumn>,
        ];
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        groups: groupsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceList)));

DeviceList.defaultProps = {
    // props
    canReadControlDevices: false,
    canCreateControlDevices: false,
    canUpdateControlDevices: false,
    devicesLoading: false,
    totalElements: 0,
    controlDevices: [],
    selectedItems: [],
    selectedAll: false,
    selectedCount: 0,
    fetchDevicesTotalAmountIncrement: 0,
    // functions
    onReload: noop,
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    onCreateDevice: noop,
    onEditDevice: noop,
    onExportDevices: noop,
    onLoadMore: noop,
};

DeviceList.propTypes = {
    // props
    canReadControlDevices: PropTypes.bool,
    canCreateControlDevices: PropTypes.bool,
    canUpdateControlDevices: PropTypes.bool,
    devicesLoading: PropTypes.bool,
    totalElements: PropTypes.number,
    controlDevices: PropTypes.array,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    selectedCount: PropTypes.number,
    fetchDevicesTotalAmountIncrement: PropTypes.number,
    // functions
    onReload: PropTypes.func,
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    onCreateDevice: PropTypes.func,
    onEditDevice: PropTypes.func,
    onExportDevices: PropTypes.func,
    onLoadMore: PropTypes.func,
};
