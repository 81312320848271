import React from 'react';
import {CM4} from "~/features/devices/constants/deviceTypes";
import {toShortDistroVersion, toShortSemanticVersion} from "~/features/base/utils/versionNumberConverter";
import {DELIVERABLE_TYPE_BUNDLE} from "~/features/deliverables/constants/deliverablesParameters";
import DistroChip from "~/features/deliverables/components/DistroChip";
import DeliverableChip from "~/features/deliverables/components/DeliverableChip";

interface SoftwareVersionProps {
    item: any;
}

export const SoftwareVersion = ({item}: SoftwareVersionProps) => {
    if (item.type === CM4) {
        const {id, version} = item.lastEssentialImageReported || {id:'', version:''};
        return <DeliverableChip deliverableId={id || ' '}
                                deliverableVersion={version}
                                deliverableType={DELIVERABLE_TYPE_BUNDLE}
                                displayValue={`${id} @ ${toShortSemanticVersion(version)}`}/>
    }
    const distroVersion = item.lastDistroVersionReported;
    const baseSoftwareVersion = item.lastBaseSoftwareVersionReported;
    return  <DistroChip key={'distroChip'} distroVersion={distroVersion}
                         displayValue={`Distro: ${toShortDistroVersion(distroVersion)} @ BSW: ${toShortSemanticVersion(baseSoftwareVersion)}`}
                         className='clickable' isShortVersion={false} toolTipMessage={undefined}/>
};
export default SoftwareVersion;
