import React, { useEffect, useState } from 'react';
import {
    controlDeviceEligibleDeliverableVersionsSelector,
    controlDeviceInfoSelector
} from '../../devices/selectors/controlDeviceSelectors';
import { useSelector } from 'react-redux';
import DefaultWhiteColumn from '../../base/components/DefaultWhiteColumn';
import ListTable from '../../base/components/table/ListTable';
import FieldOrNoData from '../../base/components/FieldOrNoData';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { DeliverableType } from '../../deliverables/constants/DeliverableType';
import { selectionByValue } from '../../base/utils/selectUtils';
import { FormattedMessage } from 'react-intl';
import { DeliverableModel } from '~/features/devices/models/DeliverableModel';
import { EligibleDerivableModel } from '~/features/devices/models/EligibleDerivableModel';
import YesOrNo from '~/features/base/components/YesOrNo';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { transformEligibleDeliverables } from '~/features/devices/utils/eligibleDeliverables';
import { deliverableSubTypeOptions } from '~/features/base/constants/filterOptions';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G
} from '~/features/deliverables/constants/deliverablesParameters';

const ALL = 'ALL';
const deliverableVersionsColumnDescriptors = [{
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:id',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'deliverableVersion',
    field: 'deliverableVersion',
    label: 'intl-msg:version',
    format: (field, item) => [field !== undefined ? <DeliverableChip
        deliverableId={item.deliverableId} deliverableType={item.deliverableType}
        deliverableVersion={field}/> : <YesOrNo/>, item.eligibleDeliverableVersion ? [
        <span className='margin-right-5 margin-left-5'>→</span>,
        <DeliverableChip deliverableVersion={item.eligibleDeliverableVersion}
                         deliverableId={item.deliverableId} deliverableType={item.deliverableType}/>
    ] : null],
},
    {
        id: 'deliverableType',
        field: 'deliverableType',
        label: 'intl-msg:type',
        format: field => <FieldOrNoData field={field}/>,
    },
    {
        id: 'deliverableSubtype',
        field: 'deliverableSubtype',
        label: 'intl-msg:subtype',
        format: field => <FieldOrNoData field={field}/>,
    }

];
const deliverableTypeOptions = [
    { id: ALL, label: ALL, value: ALL, selected: true },
    { id: DeliverableType.FILE, label: DeliverableType.FILE, value: DeliverableType.FILE },
    { id: DeliverableType.CM4G, label: DeliverableType.CM4G, value: DeliverableType.CM4G },
    { id: DeliverableType.BUNDLE, label: DeliverableType.BUNDLE, value: DeliverableType.BUNDLE },
];

interface Filters {
    deliverableType: string;
    deliverableSubtype: string;
}

const DeviceDeliverables = ({ serialNumber }) => {

    const deliverableSubTypes = [
        { id: ALL, label: ALL, value: ALL, selected: true },
        ...deliverableSubTypeOptions[DELIVERABLE_TYPE_BUNDLE],
        ...deliverableSubTypeOptions[DELIVERABLE_TYPE_CM4G]

    ].filter((item, index, self) =>
        index === self.findIndex(other => other.id === item.id)
    );
    const { installedDeliverables } = useSelector((state) => controlDeviceInfoSelector(state, { serialNumber }));
    const eligibleDeliverables = useSelector((state) => controlDeviceEligibleDeliverableVersionsSelector(state));
    const [search, setSearch] = useState<string>('');
    const [filters, setFilters] = useState<Filters>({
        deliverableType: ALL,
        deliverableSubtype: ALL,
    });
    const [deliverables, setDeliverables] = useState(installedDeliverables);
    const [optionsWithSelection, setOptionsWithSelection] = useState(deliverableTypeOptions);
    const [subtypeOptionsWithSelection, setSubtypeOptionsWithSelection] = useState(deliverableSubTypes);

    const isFiltersMatch = (deliverable: DeliverableModel): boolean => {
        if (filters.deliverableType !== ALL && deliverable.deliverableType !== filters.deliverableType) {
            return false;
        }
        if (filters.deliverableSubtype !== ALL && deliverable.deliverableSubtype !== filters.deliverableSubtype) {
            return false;
        }
        return true;
    };

    const isSearchMatch = (search, deliverableId) => {
        return deliverableId.toLowerCase().includes(search.toLowerCase());
    };

    useEffect(() => (
        setOptionsWithSelection(selectionByValue(filters.deliverableType)(deliverableTypeOptions)),
            setSubtypeOptionsWithSelection(selectionByValue(filters.deliverableSubtype)(deliverableSubTypes))
    ), [filters]);

    useEffect(() => {
            if (search !== '' || filters.deliverableType !== ALL || filters.deliverableSubtype !== ALL) {
                const filteredDeliverables = installedDeliverables?.filter(deliverable => isSearchMatch(search, deliverable.deliverableId) && isFiltersMatch(deliverable));
                setDeliverables(mergeDeliverablesWithEligiblePackageVersions(filteredDeliverables, eligibleDeliverables));
            } else {
                setDeliverables(mergeDeliverablesWithEligiblePackageVersions(installedDeliverables, eligibleDeliverables));
            }
        },
        [search, filters]);

    const handleSelect = (filterType, item) => {
        setFilters({
            ...filters,
            [filterType]: item.id,
        });

    };

    const mergeDeliverablesWithEligiblePackageVersions = (deliverables, eligibleDeliverableVersions: EligibleDerivableModel) => {
        let mergeDeliverables: DeliverableModel[] = [];
        let eligibleVersions = transformEligibleDeliverables({ ...eligibleDeliverableVersions })?.filter(deliverable => isSearchMatch(search, deliverable.deliverableId) && isFiltersMatch(deliverable));
        // add installed deliverables
        deliverables?.forEach((currentDeliverable) => {
            let deliverable: DeliverableModel = currentDeliverable;
            // add eligible version if available
            if (eligibleVersions) {
                const index = eligibleVersions.findIndex(del => del.deliverableId === currentDeliverable.deliverableId && del.deliverableType === currentDeliverable.deliverableType);
                if (index >= 0) {
                    const eligibleDeliverableVersion = eligibleVersions[index].eligibleDeliverableVersion;
                    deliverable = { ...currentDeliverable, eligibleDeliverableVersion };
                    eligibleVersions.splice(index, 1);
                }
            }
            mergeDeliverables.push(deliverable);
        });
        // add eligible deliverables
        mergeDeliverables = mergeDeliverables.concat(eligibleVersions);
        return mergeDeliverables;
    };
    return (
        <DefaultWhiteColumn className='padding-bottom-20'>
            <div className='display-flex table-toolbar  padding-bottom-5 align-items-end '>
                <div className={'width-200 '}>
                    <TableSearch
                        id='deliverableSearchInput'
                        value={search}
                        onChange={setSearch}
                        placeholder={'Search deliverable name'}
                    />
                </div>
                <div className='margin-left-15'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:deliverableType'/>
                    </label>
                    <Select options={optionsWithSelection}
                            onChange={(selectOption) => handleSelect('deliverableType', selectOption)}/>
                </div>
                <div className='margin-left-15'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:deliverableSubtype'/>
                    </label>
                    <Select options={subtypeOptionsWithSelection}
                            onChange={(selectOption) => handleSelect('deliverableSubtype', selectOption)}/>
                </div>
            </div>
            <ListTable items={deliverables}
                       columnDescriptors={deliverableVersionsColumnDescriptors}/>
        </DefaultWhiteColumn>
    );
};

export default DeviceDeliverables;
