import React, { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import GroupInput from '~/features/base/components/forms/GroupInput';
import SerialNumbersFormItem from '~/features/base/components/forms/SerialNumbersFormItem';
import { fetchFilteredDeliverables } from '~/features/deliverables/actions/deliverableActions';
import { useDispatch } from 'react-redux';
import {
    useDeviceDeliverableRevokeUninstallMutation,
    useDeviceDeliverableUninstallMutation
} from '~/api/deviceDeliverableManagement/DeviceDeliverableManagement';
import { DeviceDeliverableRequest } from '~/api/models';
import Notification from '@rio-cloud/rio-uikit/Notification';

interface UninstallFormDialogProps {
    onHide: () => void;
    show: boolean;
    deliverable: any;
}

const UninstallFormDialog: React.FC<UninstallFormDialogProps> = ({ onHide, show, deliverable }) => {
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(true);
    const uninstallInfo = deliverable?.uninstallInfo;

    const { handleSubmit, control } = useForm({
        defaultValues: {
            uninstallFromDevices: uninstallInfo?.uninstallFromDevices || [],
            uninstallGroups: uninstallInfo?.uninstallGroups || []
        }
    });

    const [uninstallDeviceDeliverable] = useDeviceDeliverableUninstallMutation();
    const [revokeUninstallDeviceDeliverable] = useDeviceDeliverableRevokeUninstallMutation();

    const handleUninstallRequest = async(requestParams: DeviceDeliverableRequest) => {
        try {
            await uninstallDeviceDeliverable(requestParams).unwrap();
            return { success: true };
        } catch (error) {
            console.error(error);
            return { success: false, error: error?.data?.message || 'intl-msg:uninstall.requestError' };
        }
    };

    const handleRevokeUninstallRequest = async(requestParams: DeviceDeliverableRequest) => {
        try {
            await revokeUninstallDeviceDeliverable(requestParams).unwrap();
            return { success: true };
        } catch (error) {
            console.error(error);
            return { success: false, error: error?.data?.message || 'intl-msg:uninstall.requestError' };
        }
    };

    const onSubmit = data => {
        if (!isValid) {
            return;
        }
        processChanges(uninstallInfo, data);
    };

    const getDelta = (arr1, arr2) => {
        return arr1.filter(item => !arr2.includes(item));
    };

    const compareObjects = (initialObject, changedObject) => {

        const added = {
            uninstallFromDevices: getDelta(changedObject.uninstallFromDevices, initialObject.uninstallFromDevices),
            uninstallGroups: getDelta(changedObject.uninstallGroups, initialObject.uninstallGroups),
        };

        const removed = {
            uninstallFromDevices: getDelta(initialObject.uninstallFromDevices, changedObject.uninstallFromDevices),
            uninstallGroups: getDelta(initialObject.uninstallGroups, changedObject.uninstallGroups),
        };

        const addedChanged = added.uninstallFromDevices.length > 0 || added.uninstallGroups.length > 0;
        const removedChanged = removed.uninstallFromDevices.length > 0 || removed.uninstallGroups.length > 0;

        return { addedChanged, removedChanged, added, removed };
    };

    const processChanges = async(initialObject, changedObject) => {
        const { addedChanged, removedChanged, added, removed } = compareObjects(initialObject, changedObject);

        if (!addedChanged && !removedChanged) {
            onHide();
            return;
        }

        const { deliverableId, deliverableVersion, deliverableType } = deliverable;
        let revokeResult = { success: true };
        let requestResult = { success: true };

        if (removedChanged) {
            const requestParams = { deliverableId, deliverableVersion, deliverableType, uninstallInfo: removed };
            revokeResult = await handleRevokeUninstallRequest(requestParams);
        }

        if (addedChanged) {
            const requestParams = { deliverableId, deliverableVersion, deliverableType, uninstallInfo: added };
            requestResult = await handleUninstallRequest(requestParams);
        }

        // Determine the message to show based on the results
        if (revokeResult.success && requestResult.success) {
            handleSuccess();
        } else if (!revokeResult.success && !requestResult.success) {
            handleError('intl-msg:uninstall.error.revokeAndUninstall', `${revokeResult?.error}; ${requestResult.error}`);
        } else {
            if (revokeResult.success) {
                handlePartialSuccess('intl-msg:uninstall.partialSuccess.revoke', requestResult?.error);
            } else {
                handlePartialSuccess('intl-msg:uninstall.partialSuccess.uninstall', revokeResult?.error);
            }
        }
    };

    const handleSuccess = () => {
        const { owningContext, deliverableType, deliverableId } = deliverable;
        dispatch(fetchFilteredDeliverables({
            page: 0,
            size: 0,
            searchCriteria: { owningContext, deliverableType, deliverableId },
        }));
        onHide();
        Notification.success(<FormattedMessage id={'intl-msg:uninstall.notification.success'}/>);
    };

    const handleError = (message, details) => {
        Notification.error(<FormattedMessage id={message} values={{ details }}/>);
    };

    const handlePartialSuccess = (message, errorDetail) => {
        Notification.warning(<FormattedMessage id={message} values={{ errorDetail }}/>);
    };

    const title = <FormattedMessage id={'intl-msg:uninstall.dialogTitle'}/>;

    const body = (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='padding-bottom-10'>
                    <div className='h5 margin-bottom-10'>
                        <span><FormattedMessage id='intl-msg:uninstall'/></span>
                    </div>
                    <Controller
                        control={control}
                        name='uninstallGroups'
                        render={({ field: { onChange, value } }) => (
                            <GroupInput groupNames={value} label={'intl-msg:uninstall.groups'} onChange={onChange}/>
                        )}
                    />
                    <Controller
                        control={control}
                        name='uninstallFromDevices'
                        render={({ field: { onChange, value } }) => (
                            <SerialNumbersFormItem
                                label={'intl-msg:uninstall.devices'}
                                serialNumbers={value}
                                disabled={false}
                                onChange={({ serialNumbers, isValid }) => {
                                    onChange(serialNumbers);
                                    setIsValid(isValid);
                                }}
                            />
                        )}
                    />
                </div>

                <div className='display-flex justify-content-end margin-top-20'>
                    <button type='submit' className={classNames('btn btn-primary')}>
                        <FormattedMessage id='intl-msg:save'/>
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <Dialog
            className='deliverable-uninstall-dialog'
            show={true}
            showCloseButton={true}
            onHide={onHide}
            title={title}
            body={body}
            footer={<></>}
            useOverflow
        />
    );
};

export default UninstallFormDialog;
