import React from 'react';
import DeliverableChip from "~/features/deliverables/components/DeliverableChip";
import {DELIVERABLE_TYPE_BUNDLE} from "~/features/deliverables/constants/deliverablesParameters";
import {toShortSemanticVersion} from "~/features/base/utils/versionNumberConverter";
import {FormattedTime} from "react-intl";
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import {
    AdaptOrderStatusesClassname,
    AdaptOrderTypesClassname
} from "~/features/adaptOrders/constants/AdaptOrderOptions";

export const adaptOrdersColumnDescriptors = [{
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:deliverableLabel',
    format: (field, data) => <DeliverableChip deliverableId={data.deliverableId} deliverableVersion={data.deliverableVersion} deliverableType={DELIVERABLE_TYPE_BUNDLE} displayValue={`${data.deliverableId}@${toShortSemanticVersion(data.deliverableVersion)}`}/>
},{
    id: 'status',
    field: 'status',
    label: 'intl-msg:logs:status',
    format: field => <span className={classNames('label label-condensed', AdaptOrderStatusesClassname[field])}>
                        <FormattedMessage id={field} />
                    </span>,
},{
    id: 'type',
    field: 'type',
    label: 'intl-msg:type',
    format: field => <span className={classNames('label label-condensed', AdaptOrderTypesClassname[field])}>
                        <FormattedMessage id={field} />
                    </span>,
},{
    id: 'createdDate',
    field: 'createDate',
    label: 'intl-msg:createdAt',
    format: field => [
        <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
    ],
},{
    id: 'updatedDate',
    field: 'updatedDate',
    label: 'intl-msg:updatedAt',
    format: field => [
        <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
    ],
},{
    id: 'expand',
    field: 'expand',
    label: 'intl-msg:showDetails',
    format: field => [
        <div/>,
    ],
}];

