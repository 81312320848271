import PropTypes from 'prop-types';
import Package from '~/prop-types/packagePropType';
import React from 'react';
import {
    DELIVERABLES_MANAGEMENT_PATH, DELIVERABLES_PATH,
} from '~/features/base/constants/routes';
import Chip from '~/features/base/components/Chip';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';
import { getDeliverableTypePath } from '~/features/deliverables/utils/utils';
import {DELIVERABLE_TYPE_BUNDLE, DELIVERABLE_TYPE_CM4G} from "~/features/deliverables/constants/deliverablesParameters";

export const DeliverableIdChip = ({ deliverableId, bsStyle, className, deliverableType, deliverableSubtype = '' }) => {
    const deliverablePath = getDeliverableTypePath(deliverableType);
    let path = `/#${DELIVERABLES_MANAGEMENT_PATH}/${deliverablePath}?deliverableIdPrefix=${deliverableId}`;
    if (deliverableType.toUpperCase() === DELIVERABLE_TYPE_BUNDLE.toUpperCase() && deliverableSubtype ) {
        path = path.concat(`&bundleType=${deliverableSubtype.toUpperCase()}`);
    }
    if (deliverableType.toUpperCase() === DELIVERABLE_TYPE_CM4G.toUpperCase() && deliverableSubtype) {
        path = path.concat(`&cm-4-g-type=${deliverableSubtype.toUpperCase()}`);
    }
    const handleOnClick = () => openInNewTab(path);
    return (
        <Chip onClick={handleOnClick} Style={bsStyle}
              className={classNames('clickable', className)}>{deliverableId}</Chip>
    );
};

export default DeliverableIdChip;

DeliverableIdChip.propTypes = {
    // props
    deliverableId: PropTypes.oneOfType([
        PropTypes.instanceOf(Package),
        PropTypes.string,
    ]).isRequired,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
