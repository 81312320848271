import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import DeviceLogsTable from '~/features/deviceLogs/components/DeviceLogsTable';
import OtherDevicesLogRequest from '~/features/deviceLogs/components/logRequest/OtherDevicesLogRequest';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import CM4LogRequest from '~/features/deviceLogs/components/logRequest/CM4LogRequest';
import SetDeviceLogLevel from '~/features/deviceLogs/components/logLevel/SetDeviceLogLevel';
import CM4LogsTable from '~/features/deviceLogs/components/logsTable/CM4LogsTable';
import { verifyCM4DeviceOffline } from '~/features/base/utils/timeUtils';
import {env, isTestEnvironment} from "~/env";

export class DeviceLogs extends PureComponent {
    onDownloadLogFile = (logFile) => {
        this.props.downloadDeviceLogFile(this.props.serialNumber, logFile);
    };
    getLogRequestComponentByDeviceType = (deviceType, serialNumber) => {
        const {
            isDeviceLogsUser,
            canCreateDeviceActions,
            canEditDeviceActions,
            controlDevice,
            controlDeviceInfo,
        } = this.props;

        if (deviceType === CM4) {
            const isCM4DeviceOffline = env.isStagingEnv ? false : verifyCM4DeviceOffline(controlDevice.lastCheckForUpdateTimestamp, controlDeviceInfo?.installedDeliverables);

            return <CM4LogRequest serialNumber={serialNumber}
                                  isDeviceLogsUser={isDeviceLogsUser}
                                  requestDisabled={isCM4DeviceOffline}
                    />;
        } else {
            return <OtherDevicesLogRequest serialNumber={serialNumber}
                                           canCreateDeviceActions={canCreateDeviceActions}
                                           canEditDeviceActions={canEditDeviceActions}
            />;
        }
    };

    getLogListByDeviceType = (deviceType, serialNumber) => {
        const {
            deviceLog,
        } = this.props;
        if (deviceType === CM4) {
            return <CM4LogsTable serialNumber={serialNumber} deviceType={deviceType}/>;
        } else {
            return <>
                <div className={'h5 margin-bottom-20'}>
                    <span className={'h5'}><FormattedMessage id='intl-msg:logs'/></span>
                </div>
                <DeviceLogsTable
                    serialNumber={serialNumber}
                    deviceLog={deviceLog}
                    onDownloadLogFile={this.onDownloadLogFile}/>
            </>;
        }
    };

    render() {
        const {
            serialNumber, controlDevice, canCreateDeviceActions, isDeviceLogsUser,
        } = this.props;

        return (
            <div style={{ backgroundColor: '#F5F5F5' }} className={'padding-bottom-10'}>
                <div className={'display-flex flex-column'}>
                    <div className='rounded-large bg-white margin-top-10 margin-left-10 margin-right-10'>
                        <SetDeviceLogLevel serialNumber={controlDevice.originalSerialNumber}
                                           deviceType={controlDevice.type}
                                           isDeviceLogsUser={isDeviceLogsUser}
                                           canCreateDeviceActions={canCreateDeviceActions}
                        />
                    </div>
                    <div className='rounded-large bg-white margin-top-10 margin-left-10 margin-right-10'>
                        {this.getLogRequestComponentByDeviceType(controlDevice.type, controlDevice.originalSerialNumber)}
                    </div>
                </div>

                <div
                    className={'rounded-large bg-white margin-top-10  margin-left-10 margin-right-10 padding-20'}>
                    {this.getLogListByDeviceType(controlDevice.type, controlDevice.originalSerialNumber)}
                </div>
            </div>)
            ;
    }
}

export default DeviceLogs;

DeviceLogs.defaultProps = {
    // props
    canCreateDeviceActions: false,
    canEditDeviceActions: false,
    isDeviceLogsUser: false,
    deviceLog: [],
    // functions
    showDeviceActionEditor: noop,
    downloadDeviceLogFile: noop,

};

DeviceLogs.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    canEditDeviceActions: PropTypes.bool,
    isDeviceLogsUser: PropTypes.bool,
    serialNumber: PropTypes.string,
    deviceAction: PropTypes.object,
    deviceLog: PropTypes.array,
    // functions
    showDeviceActionEditor: PropTypes.func,
    downloadDeviceLogFile: PropTypes.func,
};
