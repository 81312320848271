import { RangeSemanticVersion } from '~/api/models';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export class InstalledDeliverableRange {
    deliverableId: string;
    deliverableType?: string;
    versionRange?: RangeSemanticVersion;

    private constructor(id: string, deliverableType: string, versionRange: RangeSemanticVersion) {
        this.deliverableId = id;
        this.deliverableType = deliverableType;
        this.versionRange = versionRange;

    }

    static create(d: string) {
        return d && JSON.parse(decodeURIComponent(d));
    }

    static generateTag(d: InstalledDeliverableRange) {
        const { versionRange } = d;
        if (versionRange?.minValue && versionRange?.maxValue) {
            return `${d.deliverableId} ${toShortSemanticVersion(versionRange.minValue)} to ${toShortSemanticVersion(versionRange.maxValue)}`;
        }
        if (versionRange?.minValue) {
            return `${d.deliverableId} min: ${toShortSemanticVersion(versionRange.minValue)}`;
        }

        if (versionRange?.maxValue) {
            return `${d.deliverableId} max: ${toShortSemanticVersion(versionRange.maxValue)}`;
        }

    }

    static stringify(d: InstalledDeliverableRange) {
        return encodeURIComponent(JSON.stringify(d));
    }

}
