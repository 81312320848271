import React, { Component } from 'react';
import classNames from 'classnames';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import ReleaseState from '~/features/base/components/ReleaseState';
import { FormattedMessage } from 'react-intl';
import uid from '~/features/base/utils/uid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import {
    canDeleteDeliverablesSelector,
    canUpdateDeliverablesSelector,
    deliverableByVersionSelector,
    bundleReleaseStateWarningSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { fetchFilteredArtifacts } from '~/features/deliverables/features/artifacts/actions/artifactActions';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { defaultNetworkCodeSelector } from '~/features/base/selectors/defaultNetworkCodeSelector';
import size from 'lodash/fp/size';
import join from 'lodash/fp/join';
import { ENTITY_UPDATE_EVENT_SCOPE_DISTRO } from '~/features/base/constants/entities';
import noop from 'lodash/fp/noop';
import { showUpdateEventReportDialog } from '~/features/updateEvents/actions/updateEventReportActions';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import DeliverableDetailsTabs from '~/features/deliverables/components/details/DeliverableDetailsTabs';
import { withRouter } from 'react-router-dom';
import {
    DETAILS_PATH,
    EVENTS_PATH, HISTORY_PATH, PARAMETERS_PATH,
    WHITELIST_PATH,
} from '~/features/base/constants/routes';
import DeliverablePropertiesContainer
from '~/features/deliverables/components/details/DeliverablePropertiesContainer';
import DeliverableUpdateEventsContainer
from '~/features/deliverables/components/details/DeliverableUpdateEventsContainer';
import { lowerCase } from 'lodash';
import DeliverableWhitelistContainer
from '~/features/deliverables/components/details/DeliverableWhitelistContainer';
import { truncate } from '~/features/apps/utils/appsUtils';
import DeliverableHistoryContainer from '~/features/deliverables/components/details/DeliverableHistoryContainer';
import DistroParametersContainer from '~/features/deliverables/components/details/DistroParametersContainer';
import { showDeliverableEditorDialog } from '~/features/deliverables/actions/deliverableActions';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import DeliverableMoreActions from "~/features/deliverables/components/moreActions/DeliverableMoreActions";

export class DeliverableVersionDetailsTable extends Component {
    constructor(props) {
        super(props);
        this.name = uid();
        this.keepArtifactsOpen = uid();
        this.state = {
            selectedTab: DETAILS_PATH,
        };
    }

    getSearchCriteria() {
        const { selectedDeliverableDetails } = this.props;
        const deliverableId = isEqual(lowerCase(selectedDeliverableDetails.deliverableType), DELIVERABLE_TYPE_DISTRO) ?
            'distro' : selectedDeliverableDetails.deliverableId;
        return {
            deliverableId,
            targetDeliverableVersion: selectedDeliverableDetails.deliverableVersion,
        };
    }

    onSelectTab = (view) => {
        this.setState({
            selectedTab: view,
        });
    };

    onShowReport = () => {
        const { selectedDeliverableDetails } = this.props;
        const searchCriteria = this.getSearchCriteria();
        const deliverableId = isEqual(lowerCase(selectedDeliverableDetails.deliverableType), DELIVERABLE_TYPE_DISTRO) ?
            'distro' : selectedDeliverableDetails.deliverableId;
        this.props.showUpdateEventReportDialog({
            deliverableId,
            deliverableVersion: selectedDeliverableDetails.deliverableVersion,
            deliverableType: selectedDeliverableDetails.deliverableType,
            searchCriteria,
            scope: ENTITY_UPDATE_EVENT_SCOPE_DISTRO,
        });
    };

    onEditDeliverableVersion = (event) => {
        const { selectedDeliverableDetails } = this.props;
        const {
            deliverableId,
            deliverableVersion,
            description,
            downloadUrl,
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        } = selectedDeliverableDetails;
        this.props.onShowDeliverableEditorDialog({
            isNew: false,
            isDeliverableVersion: true,
            deliverableId,
            description,
            downloadUrl,
            deliverableVersion: toShortSemanticVersion(deliverableVersion),
            fileInfo,
            networkCodeWhitelist,
            owningContext,
            priority,
            releaseState,
        });
    };

    renderRoutes() {
        const { selectedDeliverableDetails } = this.props;
        const { selectedTab } = this.state;

        const historyTabContainer = <DeliverableHistoryContainer
            deliverableVersion={toShortSemanticVersion(selectedDeliverableDetails?.deliverableVersion)}
            deliverableId={selectedDeliverableDetails?.deliverableId}
            deliverableType={selectedDeliverableDetails?.deliverableType}
        />;
        const deliverableType = selectedDeliverableDetails?.deliverableType?.toLowerCase() || '';
        switch (deliverableType) {
            case DELIVERABLE_TYPE_DISTRO: {
                switch (selectedTab) {
                    case DETAILS_PATH: {
                        const distroDetails = {
                            ...selectedDeliverableDetails,
                            shortDistroVersion: toShortSemanticVersion(
                                selectedDeliverableDetails?.deliverableVersion),
                            shortBaseSwVersion: toShortSemanticVersion(
                                selectedDeliverableDetails?.distroInfo?.baseSoftwareVersion),
                        };
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={distroDetails}/>;
                    }
                    case EVENTS_PATH:
                        return <DeliverableUpdateEventsContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case WHITELIST_PATH:
                        return <DeliverableWhitelistContainer selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case PARAMETERS_PATH:
                        return <DistroParametersContainer
                            shortDistroVersion={toShortSemanticVersion(
                                selectedDeliverableDetails.deliverableVersion)} />;
                    case HISTORY_PATH:
                        return historyTabContainer;
                    default:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                }
            }
            case DELIVERABLE_TYPE_APP: {
                switch (selectedTab) {
                    case DETAILS_PATH:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case EVENTS_PATH:
                        return <DeliverableUpdateEventsContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case WHITELIST_PATH:
                        return <DeliverableWhitelistContainer selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case HISTORY_PATH:
                        return historyTabContainer;
                    default:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                }
            }
            case DELIVERABLE_TYPE_FILE: {
                switch (selectedTab) {
                    case DETAILS_PATH:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case EVENTS_PATH:
                        return <DeliverableUpdateEventsContainer
                            selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case WHITELIST_PATH:
                        return <DeliverableWhitelistContainer selectedDeliverableDetails={selectedDeliverableDetails} />;
                    case HISTORY_PATH:
                        return historyTabContainer;
                    default:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                }
            }
            case DELIVERABLE_TYPE_CM4G: {
                switch (selectedTab) {
                    case DETAILS_PATH:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case EVENTS_PATH:
                        return <DeliverableUpdateEventsContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case WHITELIST_PATH:
                        return <DeliverableWhitelistContainer selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case HISTORY_PATH:
                        return historyTabContainer;
                    default:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                }
            }
            case DELIVERABLE_TYPE_BUNDLE: {
                switch (selectedTab) {
                    case DETAILS_PATH:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case EVENTS_PATH:
                        return <DeliverableUpdateEventsContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case WHITELIST_PATH:
                        return <DeliverableWhitelistContainer selectedDeliverableDetails={selectedDeliverableDetails}/>;
                    case HISTORY_PATH:
                        return historyTabContainer;
                    default:
                        return <DeliverablePropertiesContainer
                            selectedDeliverableDetails={selectedDeliverableDetails}/>;
                }
            }
            default:
                return <DeliverablePropertiesContainer selectedDeliverableDetails={selectedDeliverableDetails}/>;
        }
    }

    renderTabs() {
        const { basePath, selectedDeliverableDetails } = this.props;
        const { selectedTab } = this.state;
        return <DeliverableDetailsTabs basePath={basePath} selectedDeliverableDetails={selectedDeliverableDetails}
                                       onSelectTab={this.onSelectTab} view={selectedTab}/>;
    }

    renderBundleWarning() {
        return <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={'intl-msg:deliverables.bundleReleaseStateWarning'}/>
            </Tooltip>}>
            <span
                className='rioglyph rioglyph-info-sign text-color-warning text-size-20 margin-left-10'/>
        </OverlayTrigger>;
    }

    render() {
        const {
            selectedDeliverableDetails,
            canDeleteDeliverables,
            canUpdateDeliverables,
            hasBundleReleaseStateWarning,
            isDistroOrEssentialImage
        } = this.props;
        const tabs = this.renderTabs();
        const routes = this.renderRoutes();
        const releaseStateWarning = this.renderBundleWarning();
        const columnWidth = isDistroOrEssentialImage ?
            'col-md-9' :
            'col-md-8';

        return (
            <div className={columnWidth}>
                <div className={classNames('bg-white clearfix')}>
                    {selectedDeliverableDetails ?
                        <div className='panel panel-default margin-bottom-25'>
                            <div className='panel-heading height-50'>
                                <div className='col-md-6'>
                                    <div className='margin-bottom-10 indicated-button'>
                                        <span className='text-size-20 text-bold'>
                                            {truncate(selectedDeliverableDetails.deliverableId,
                                                20)} / {toShortSemanticVersion(
                                            selectedDeliverableDetails.deliverableVersion)} {<ReleaseState
                                            className='margin-left-10'
                                            releaseState={selectedDeliverableDetails.releaseState}/>}
                                        </span>
                                        {selectedDeliverableDetails.rolloutTracking?.trackingEnabled &&
                                            <OverlayTrigger placement='top' overlay={
                                                <Tooltip id='tooltip' className='top-right'>
                                                    <FormattedMessage id={'intl-msg:deliverableRollout.tooltip'}/>
                                                </Tooltip>}>
                                                <span className='rioglyph rioglyph-beacon text-size-20 margin-left-5'/>
                                            </OverlayTrigger>}
                                        {hasBundleReleaseStateWarning && releaseStateWarning}
                                    </div>
                                </div>
                                <div className='display-flex justify-content-end gap-10 text-right'>
                                    <a id='report-button'
                                        className='btn btn-default'
                                        onClick={this.onShowReport}
                                    >
                                        <b>
                                            <span className='rioglyph rioglyph-line-chart'
                                                aria-hidden='true'></span>&nbsp;
                                            <FormattedMessage id='intl-msg:showReport' />
                                        </b>
                                    </a>
                                    <DeliverableMoreActions deliverable={selectedDeliverableDetails}/>
                                    <button type='button' className='btn btn-primary width-50'
                                        disabled={!(canUpdateDeliverables && canDeleteDeliverables)}
                                        data-key-deliverable-id={selectedDeliverableDetails.deliverableId}
                                        data-key-deliverable-type={selectedDeliverableDetails.deliverableType}
                                        data-key-deliverable-version={toShortSemanticVersion(
                                            selectedDeliverableDetails.deliverableVersion)}
                                        onClick={this.onEditDeliverableVersion}>
                                        <b><FormattedMessage id='intl-msg:edit' /></b>
                                    </button>
                                </div>
                            </div>
                            <div className='padding-top-10 margin-left-5 margin-right-5'>
                                {tabs}
                            </div>
                            <div className='margin-left-5 margin-right-5 margin-bottom-5'>
                                {routes}
                            </div>
                        </div> :
                        <NotFoundState headline={<FormattedMessage id='intl-msg:noVersionSelected' />}
                            message={<FormattedMessage id='intl-msg:pleaseSelectDeliverablesVersion' />} />
                    }
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const nextDetails = nextProps.selectedDeliverableDetails;
        const thisDetails = this.props.selectedDeliverableDetails;
        this.keepArtifactsOpen = uid();

        if (nextProps.hasBundleReleaseStateWarning !== this.props.hasBundleReleaseStateWarning) {
            return true;
        }

        if (!isEqual(nextDetails, thisDetails)) {
            return true;
        }
        if (!nextProps.deliverablesLoading && this.props.deliverablesLoading && isEmpty(
            this.props.selectedDeliverableDetails)) {
            return true;
        }

        if (!isEqual(this.state.selectedTab, nextState.selectedTab)) {
            return true;
        }

        return false;
    }

    componentWillReceiveProps = (nextProps) => {
        const { selectedDeliverableDetails } = nextProps;
        const { selectedDeliverableDetails: currentSelectedDeliverableDetails } = this.props;

        if (selectedDeliverableDetails && currentSelectedDeliverableDetails && ( currentSelectedDeliverableDetails?.deliverableId !== selectedDeliverableDetails?.deliverableId
        || !isEqual(currentSelectedDeliverableDetails?.deliverableVersion ,selectedDeliverableDetails?.deliverableVersion))) {
            this.setState({ selectedTab: DETAILS_PATH });
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        selectedDeliverableDetails: deliverableByVersionSelector(state, ownProps),
        canUpdateDeliverables: canUpdateDeliverablesSelector(state),
        canDeleteDeliverables: canDeleteDeliverablesSelector(state),
        defaultNetworkCodeList: defaultNetworkCodeSelector(state),
        hasBundleReleaseStateWarning: !!bundleReleaseStateWarningSelector(state, deliverableByVersionSelector(state, ownProps), false),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchFilteredArtifacts: (deliverableType, deliverableId, deliverableVersion, deliverableNamePrefix,
            owningContext) => {
            dispatch(fetchFilteredArtifacts({
                page: 0,
                searchCriteria: {
                    type: deliverableType?.toLowerCase(),
                    otaIdPrefix: deliverableId,
                    otaVersionPrefix: deliverableVersion,
                    contextList: owningContext ? [owningContext] : [],
                    deliverableNamePrefix,
                },
            }));
        },
        showUpdateEventReportDialog: (options) => {
            dispatch(showUpdateEventReportDialog(options));
        },
        onShowDeliverableEditorDialog: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliverableVersionDetailsTable));

DeliverableVersionDetailsTable.defaultProps = {
    // props
    deliverablesLoading: false,
    selectedDeliverableId: '',
    basePath: '',
    selectedDeliverableVersion: '',
    canUpdateDeliverables: false,
    canDeleteDeliverables: false,
    defaultNetworkCodeList: [],
    isDistroOrEssentialImage: false,
    // functions
    showUpdateEventReportDialogs: noop,
};

DeliverableVersionDetailsTable.propTypes = {
    // props
    deliverablesLoading: PropTypes.bool,
    selectedDeliverableId: PropTypes.string,
    selectedDeliverableVersion: PropTypes.string,
    basePath: PropTypes.string,
    canUpdateDeliverables: PropTypes.bool,
    canDeleteDeliverables: PropTypes.bool,
    isDistroOrEssentialImage: PropTypes.bool,
    defaultNetworkCodeList: PropTypes.array,
    // functions
    showUpdateEventReportDialogs: PropTypes.func,
};
